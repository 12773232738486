import React from "react"
import Layout from "../components/layout"
import Card from "../components/Card/Card.js"
import GridSpan from "../components/GridSpan/GridSpan.js"
import {Link} from "gatsby"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export default function FourOhFour() {
    return (
        <Layout>
          <GatsbySeo 
            title="404"
            description="404 Error Page"
          />
            <GridSpan span={[0,1,2,3]}/>
            <GridSpan span={[12, 10, 8, 6]}>
                <Card>
                    <GridSpan span={[2,2,2,2]}>

                        <h2>404</h2>
                        <p>We're sorry, we can't seem to find a page here.</p>
                        <Link to="/">Return home</Link>
              </GridSpan>
            </Card>
          </GridSpan>
  
        </Layout>  )
  }